<template>
  <div>
     <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>任务详情  </div>
     <el-card class="box-card"  >
                <div>  <span style="font-size:16px">{{Detailstr.taskName}}</span>
                      <span  class="mini">{{Detailstr.taskStateName}}</span>
                      <span class="text" @click="er()">下载本项目小程序二维码</span>
                </div>
                <div class="position">
                  <!-- 已终止 ---时则该按钮为不可点击状态。 -->
                  <el-button type="primary" @click="gotask()" :disabled="Detailstr.taskState==10032">任务结算</el-button> 
                   <el-button type="primary" @click="management()">充值</el-button>
                </div>
                <div class="two">
                    起始日期 {{Detailstr.taskStartTime}} ~ {{Detailstr.taskEndTime}}
                    <span class="listspan">结算周期 {{Detailstr.settlementCycleName}}</span>
                    <span class="listspan">结算标准  {{Detailstr.price}} {{Detailstr.settlementStandardName}}</span>
                    <!-- <span class="listspan">所属行业 {{Detailstr.industryName}}</span> -->
                </div>
                <div style="margin-top:10px">
                    {{Detailstr.taskDesc}}
                </div>
                <!-- tab -->
                <div class="content-view">
                  <el-tabs v-model="activeName" type="card" @tab-click="handleClick" >
                    <!-- <el-tab-pane label="签约个体工商户管理" name="first" >
                       <tab1 ref="tab"></tab1>
                    </el-tab-pane> -->
                    <el-tab-pane label="任务领取记录" name="second">
                       <tab2 ref="tabb2"></tab2>
                    </el-tab-pane>
                    <el-tab-pane label="任务结算记录" name="third">
                       <tab3 ref="tabb3"></tab3>
                    </el-tab-pane>
                 </el-tabs>
                </div>
                
    </el-card>
  </div>
</template>

<script>
import {downloadIamge} from "@/utils/util.js"
import {Industry,getDetail,createdTask} from  "@/api/task";
// import tab1 from "./tab1.vue"
import tab2 from "./tab2.vue"
import tab3 from "./tab3.vue"

export default {
  data() {
    return {
      Detailstr:{},
      activeName: 'second'
    };
  },
  mounted(){
    this.$refs.tabb2.getDataList()
     this.$refs.tabb2.type()
    this.Detail()
  },
   components: {
    //  tab1,
   tab2,tab3},
   methods: {
    // 上部详情
    Detail(){
        getDetail({
          taskId:this.$route.query.taskId
        }).then((data=>{
         if (data && data.code==200) {
           this.Detailstr=data.data
            }
        }))
    },
    // tab
     handleClick(tab) {
       console.log(tab.index,"oo");
        if (tab.index==0) {
         this.$refs.tabb2.getDataList()
         this.$refs.tabb2.type()
        }
        if (tab.index==1) {
         this.$refs.tabb3.getDataList()
         this.$refs.tabb3.dropTran()
        }
      
  
      },
      gotask(){
        this.$router.push({name:"/task/taskSettlement",query:{taskId:this.Detailstr.taskId}})
      },
      management(){
        this.$router.push({name:"capital-recharge"})
      },
      er(){
        downloadIamge(this.Detailstr.weChatUrl, this.Detailstr.taskName+'小程序二维码')
      },
    }
};
</script>

<style  scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.box-card{
  margin-top: 10px;
  position: relative;
}
.mini{
      display: inline-block;
      height: 20px;
      line-height: 20px;
      text-align: center;
      width:60px;
      border: 1px solid #9e99b9;
      border-radius: 25px ;
      margin-left:14px;
      transform: translateY(-2px);
      font-size: 10px;
  }
  .text{
    color: #9e99b9;
    text-decoration: none;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
}
  .listspan{
      margin-left: 30px;
  }
  .percalss{
    display: inline-block;
    height: 20px;
    width:45%;
     margin-top: 6px;
  }
.content-view {
	  margin-top: 20px;
	}
.content-view  /deep/.el-progress-bar{
   width: 80%;
}
.content-view  /deep/ .el-progress__text{
 font-size: 10px !important;
}
.position{
  position: absolute;
  right:50px;
  top:20px
}
.two{
      font-size: 13px;
      margin-top: 10px;
  }
div /deep/.el-tabs__item:hover {
    /* color:#ffff; */
    background-color: #f2f2f2;
}
div /deep/.el-tabs__item.is-active {
    color:#ffff;
    background-color: #9e99b9;
}
div /deep/.el-tabs__item{
  width:500px !important;
  text-align: center;
  box-shadow: 3px 5px 10px #edf3f5;
}


</style>


