<template>
  <div class="box">
    <p class="tabtitle">任务领取记录</p>
      <el-form :model="rule"   label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" class="item" label-width="50px">
                     <el-input v-model="rule.name" style="width:250px" ></el-input>
                 </el-form-item>
                  <el-form-item label="身份证号" class="item">
                     <el-input v-model="rule.idCard" style="width:300px" ></el-input>
                 </el-form-item>
                  <el-form-item label="领取方式" class="item">
                     <el-select v-model="rule.receiveType" placeholder="请选择" style="width:160px">
                        <el-option
                            v-for="item in tranList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item>
                 <el-form-item label="领取状态" class="item">
                     <el-select v-model="rule.receiveState" placeholder="请选择" style="width:160px">
                        <el-option
                            v-for="item in tranList1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item>
                <el-button type="primary" style="margin-left:40px;width:100px"  @click="getDataList(1)">查询</el-button> 
                <el-button type="primary" style="margin-left:40px;width:100px" @click="exportt()">导出</el-button>
      </el-form>
       <el-table
      v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="姓名"
         width="100"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="idCard"
        label="身份证号"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="receiveStateText"
        label="领取状态">
      </el-table-column>
      
      <el-table-column
       header-align="center"
        align="center"
        prop="bankNumber"
        label="银行账号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="phone"
        label="手机号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="receiveTime"
        label="任务领取时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="receiveTypeText"
        label="领取方式">
      </el-table-column>
      <!-- <el-table-column
       header-align="center"
        align="center"
        prop="creditCode"
        label="统一社会信用代码">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="businessStateText"
        width="130"
        label="账户状态">
      </el-table-column> -->
    </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
  </div>
</template>

<script>
import {PageList,getDownBoxByType,downloadExcel} from  "@/api/task";
export default {
  data() {
    return {
        load:false,
        tran:"",
       tranList:[],
       tranList1:[],
       rule:{
          "name": "",
          "idCard": "",
          "receiveType": null,
          "receiveState": null,
          "type": null,
        },
        tableData: [],
        pageSize: 10,
        totalCount: 0,//数据长度
        currPage: 1,
    };
  },
  mounted(){
  
  },
  activated(){
      this.type()
      this.getDataList()
  },
  methods: {
   getDataList(a){
       this.load=true
        if (a) {  //判断是不是搜索
          this.currPage=1 
          this.pageSize=10
        }
      PageList({
          "taskId":this.$route.query.taskId,
          "name": this.rule.name,
          "idCard": this.rule.idCard,
          "receiveType":this.rule.receiveType,//领取方式
          "receiveState":this.rule.receiveState,//领取状态
          "type": 2,
          "current": this.currPage,
          "size": this.pageSize,
        }).then((data=>{
          this.load=false
         if (data && data.code==200) {
             this.totalCount=data.data.total
             this.tableData=data.data.records
            }
        }))
   },
    type(){
       getDownBoxByType({
          type:"BUSINESS_TASK_LOG_APPLY_TYPE"
        }).then((data=>{
        if (data && data.code==200) {
           this.tranList=data.data
            }
        }))
      // 任务类型
         getDownBoxByType({
          type:"BUSINESS_TASK_LOG_STATE"
        }).then((data=>{
          console.log(data);
        if (data && data.code==200) {
           this.tranList1=data.data
            }
        }))
   },
     // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
}
};
</script>

<style  scoped>

.tabtitle{
  height: 40px;
  border-bottom:1px solid darkgray;
  line-height: 40px;
  width: 120%;
  font-size: 14px;;
}
.item{
    display: inline-block;
}
</style>


